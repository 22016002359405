.top-bar {
  background-color: white !important;
  height: auto; 
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: 'Arial', 'Helvetica', sans-serif;
  padding: 10px;
  box-shadow: none !important;
}

.tabs {
  color: #2a2e31 !important;
  font-size: 14px;
  font-weight: bold;
}

.tab {
  transition: background-color 0.3s; 
  &:hover {
    color: #70b2b9;
  }
}

.tabs .Mui-selected {
  color: #70b2b9 !important;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.user-name{
  color: #393F44;
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.user-description {
  font-size: 14px;
  color: #393F44;
}


@media screen and (min-width: 768px) {
  .profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.profile-pic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 10px;
}